import ContentLoader from 'react-content-loader';

export const Skeleton = ({
  width,
  height,
  radius = '4',
  className,
}: {
  width: string;
  height: string;
  radius?: string;
  className?: string;
}): JSX.Element => (
  <ContentLoader
    speed={2}
    width={width}
    height={height}
    backgroundColor="#f3f3f3"
    foregroundColor="#ecebeb"
    className={className}
  >
    <rect x="0" y="0" width="100%" height={height} rx={radius} />
  </ContentLoader>
);
